<style lang="scss" scoped>
.coupons-box {
  width: 100%;
  .bg {
    width: 100%;
    position: relative;
    .bg-img {
      width: 7.5rem;
      height: 13.36rem;
      display: block;
    }
    .btn {
      position: absolute;
      top: 10.48rem;
      left: 1.2rem;
      width: 5rem;
      height: 1.12rem;
      background-image: linear-gradient(90deg, #fbe381 0%, #f6c24a 100%);
      box-shadow: 0 8rem 16rem 0 rgb(246 194 74 / 40%);
      border-radius: 54.5rem;
      font-size: 0.44rem;
      color: #222;
      text-align: center;
      line-height: 1.12rem;
      font-weight: bold;
      text-decoration: none;
      -webkit-animation: scaleBtn 2s 0.1s linear infinite;
      animation: scaleBtn 2s 0.1s linear infinite;
    }

    @keyframes scaleBtn {
      0% {
        transform: scale(1);
      }
      25% {
        transform: scale(1.1);
      }
      50% {
        transform: scale(1);
      }
      75% {
        transform: scale(1.1);
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.2rem;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .footer-left {
      font-size: 0.32rem;
      color: #000000;
      font-weight: bold;
      img {
        width: 0.88rem;
        height: 0.88rem;
        display: inline-block;
        margin-right: 0.32rem;
        vertical-align: middle;
      }
    }
    .footer-right {
      width: 1.92rem;
      height: 0.76rem;
      background-image: linear-gradient(90deg, #fbe381 0%, #f6c24a 100%);
      border-radius: 0.38rem;
      font-size: 0.28rem;
      color: #222222;
      font-weight: bold;
      text-align: center;
      line-height: 0.76rem;
      text-decoration: none;
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    img {
      width: 6rem;
      height: 2.16rem;
      display: block;
      float: right;
    }
  }
}
</style>

<template>
  <div class="coupons-box">
    <div class="bg">
      <img class="bg-img" src="../assets/app/bg.png" alt="" />
      <a class="btn" id="btn" :href="linkHref" @click="downloadClick">
        立即下载
      </a>
    </div>
    <div class="footer">
      <div class="footer-left">
        <img src="../assets/app/logo-bottom.png" alt="" />
        <span>下载APP，立减5元</span>
      </div>
      <a class="footer-right" :href="linkHref" @click="downloadClick">
        立即下载
      </a>
    </div>
    <div class="modal" v-if="showModal" @click="hideModal">
      <img src="../assets/app/tip.png" alt="" />
    </div>
  </div>
</template>

<script>
import { buriedPoint } from '@/api/api';

export default {
  name: 'DownloadApp',
  components: {},
  props: {},
  data() {
    return {
      linkHref: '#',
      showModal: false,
      weixinFlage: false,
    };
  },
  computed: {},
  created() {
    // this.getModalSource()

    buriedPoint({
      eventInfos: [
        {
          eventVariableName: 'browser',
          eventAttribute: this.weixinFlage ? '微信浏览器' : '普通浏览器',
        },
      ],
      eventNo: '32', //见事件编号列
      eventName: this.$route.query.type, //见事件名称列
      parkCode: '', //为空可不传
      source: 0, //0:微信。1：支付宝
      token: sessionStorage.getItem('_token'), //必传
      vin: '', //为空可不传
    });
  },
  activated() {},
  mounted: function () {},
  destroyed() {},
  methods: {
    hideModal() {
      this.showModal = false;
    },
    getModalSource() {
      let isAndroid = false,
        isIOS = false,
        isIOS9 = false,
        version,
        u = navigator.userAgent,
        ua = u.toLowerCase();

      // 是否在微信打开
      if (
        /micromessenger/i.test(u.toLowerCase()) ||
        typeof navigator.wxuserAgent !== 'undefined'
      ) {
        // this.showModal = true
        this.weixinFlage = true;
        return;
      }

      //Android系统
      if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
        //android终端或者uc浏览器
        isAndroid = true;
        window.location.href = 'neolix://main';
        setTimeout(() => {
          let hidden =
            window.document.hidden ||
            window.document.mozHidden ||
            window.document.msHidden ||
            window.document.webkitHidden;
          if (typeof hidden == 'undefined' || hidden == false) {
            //应用宝下载地址
            this.linkHref =
              'https://cybertron-public.neolix.net/NeolixData/Default/frontend/AppVersion/CK/NeolixApp.apk';
          }
          // this.linkHref= 'https://sourl.cn/kzDnJB'
        }, 3000);
      }
      //ios
      if (ua.indexOf('like mac os x') > 0) {
        let regStr_saf = /os [\d._]*/gi;
        let verinfo = ua.match(regStr_saf);
        version = (verinfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.');
      }
      let version_str = version + '';
      // ios9以上
      if (version_str !== 'undefined' && version_str.length > 0) {
        version = parseInt(version);
        if (version >= 8) {
          isIOS9 = true;
          window.location.href = 'neolix://main';
          setTimeout(() => {
            let hidden =
              window.document.hidden ||
              window.document.mozHidden ||
              window.document.msHidden ||
              window.document.webkitHidden;
            if (typeof hidden == 'undefined' || hidden == false) {
              //App store下载地址
              this.linkHref = 'https://apps.apple.com/cn/app/1607432751';
            }

            // this.linkHref= `https://apps.apple.com/cn/app/1607432751`
          }, 3000);
        } else {
          isIOS = true;
          window.location.href = 'neolix://main';
          setTimeout(() => {
            let hidden =
              window.document.hidden ||
              window.document.mozHidden ||
              window.document.msHidden ||
              window.document.webkitHidden;
            if (typeof hidden == 'undefined' || hidden == false) {
              //App store下载地址
              this.linkHref = 'https://apps.apple.com/cn/app/1607432751';
            }

            // this.linkHref= 'https://apps.apple.com/cn/app/1607432751'
          }, 3000);
        }
      }
    },
    downloadClick() {
      this.getModalSource();
      if (this.weixinFlage) {
        this.showModal = true;
      }
      buriedPoint({
        eventInfos: [
          {
            eventVariableName: 'browser',
            eventAttribute: this.weixinFlage ? '微信浏览器' : '普通浏览器',
          },
        ],
        deviceNo: null,
        eventNo: '31', //见事件编号列
        eventName: this.$route.query.type, //见事件名称列
        parkCode: '', //为空可不传
        source: 0, //0:微信。1：支付宝
        token: sessionStorage.getItem('_token'), //必传
        vin: '', //为空可不传
      });
    },
  },
};
</script>
